@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Sarabun", sans-serif;
}

:root {
  --color-green: #2dc76d;
  --color-dark-green: #249f57;
  --color-light-green: rgba(45, 199, 109, 0.15);
  --color-blue: #2192ff;
  --color-black: #161616;
  --color-black-2: #061126;
  --color-grey: #898282;
  --color-grey-2: #6d788d;
  --color-dark-grey-2: #576070;
  --color-grey-3: #e6e6e6;
  --color-dark-grey-3: #cfcfcf;
  --color-red: #f95057;
  --color-dark-red: #c74045;
  --color-light-red: rgba(249, 80, 87, 0.2);
  --color-orange-red: #e94800;
  --color-dark-orange-red: #d14000;
  --color-orange: #ff731d;
  --color-dark-orange: #e5671a;
  --color-light-orange: rgba(242, 111, 58, 0.15);
  --color-blue-2: #0760fc;
  --color-dark-blue-2: #0656e2;
  --color-yellow: #ffc52f;
  --color-dark-yellow: #e5b12a;
  --color-light-yellow: rgba(255, 197, 47, 0.2);
  --color-grey-4: #b6bbc6;
  --color-light-grey-4: #f4f4f4;
  --color-grey-5: #eff0f6;
  --color-dark-grey-5: #d7d8dd;
  --color-dark-orange-red: #d14000;
}

.sarabun {
  font-family: "Sarabun", sans-serif;
}

.comfortaa {
  font-family: "Comfortaa", sans-serif;
}

.prompt {
  font-family: "Prompt", sans-serif;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.barlow {
  font-family: "Barlow", sans-serif;
}

.kanit {
  font-family: "Kanit", sans-serif;
}

.login-bg {
  background-image: url("./img/login_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.text-7 {
  font-size: min(0.68vw, 7px);
}

.text-8 {
  font-size: min(0.78vw, 8px);
}

.text-9 {
  font-size: min(0.88vw, 9px);
}

.text-10 {
  font-size: min(0.98vw, 10px);
}

.text-11 {
  font-size: min(1.07vw, 11px);
}

.text-12 {
  font-size: min(1.17vw, 12px);
}

.text-13 {
  font-size: min(1.27vw, 13px);
}

.text-14 {
  font-size: min(1.37vw, 14px);
}

.text-15 {
  font-size: min(1.46vw, 15px);
}

.text-16 {
  font-size: min(1.56vw, 16px);
}

.text-18 {
  font-size: min(1.75vw, 18px);
}

.text-20 {
  font-size: min(1.95vw, 20px);
}

.text-22 {
  font-size: min(2.15vw, 22px);
}

.text-24 {
  font-size: min(2.34vw, 24px);
}

.text-26 {
  font-size: min(2.54vw, 26px);
}

.custom-input {
  display: flex;
}

.custom-input input {
  -webkit-transition: 0.4s;
  transition: border 0.4s;
  outline: none;
}

.custom-input input:hover {
  border-color: var(--color-green);
}

.custom-input input:focus {
  border-color: var(--color-green);
}

.custom-input input.error {
  border-color: var(--color-red);
}

.custom-input input.error:hover {
  border-color: var(--color-red);
}

.custom-input input::placeholder {
  font-weight: 300;
}

.custom-input-password span {
  background-repeat: no-repeat;
  background-size: contain;
  top: 50%;
  margin-top: calc(min(2.34vw, 24px) / -2);
  width: min(2.34vw, 24px);
  aspect-ratio: 1;
  position: absolute;
  right: min(1.66vw, 17px);
  cursor: pointer;
}

.custom-input-checkbox {
  display: flex;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-input-checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.custom-input-checkbox label {
  display: flex;
  align-items: center;
}

.custom-input-checkbox label::before {
  content: "";
  width: min(2.34vw, 24px);
  aspect-ratio: 1;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid var(--color-green) min(0.1vw, 1px);
  border-radius: min(0.39vw, 4px);
}

.custom-input-checkbox:hover label::before {
  background-color: transparent;
}

.custom-input-checkbox input:checked ~ ::before {
  background-color: var(--color-green);
  background-image: url("/public/img/icons/icon-tick-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}

.kitchen-input-checkbox {
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.kitchen-input-checkbox label {
  position: relative;
}

.kitchen-input-checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: min(1.76vw, 18px);
  z-index: 999;
}

.kitchen-input-checkbox label::before {
  content: "";
  width: min(1.76vw, 18px);
  height: fit-content;
  aspect-ratio: 1;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid #909090 min(0.1vw, 1px);
}

.kitchen-input-checkbox:hover label::before {
  background-color: transparent;
}

.kitchen-input-checkbox input:checked ~ label::after {
  position: absolute;
  content: "";
  width: min(1.86vw, 19px);
  height: min(1.76vw, 18px);
  background-color: transparent;
  background-image: url("/public/img/icons/icon-tick-kitchen-green.png");
  background-repeat: no-repeat;
  background-size: cover;
  top: -5%;
  left: 10%;
}

.shadow-1-8 {
  filter: drop-shadow(
    0px min(0.1vw, 1px) min(0.78vw, 8px) rgba(110, 110, 110, 0.1)
  );
}

.shadow-3-3 {
  box-shadow: 0px min(0.29vw, 3px) min(0.29vw, 3px) 0px rgba(0, 0, 0, 0.04);
}

.shadow-2-8 {
  box-shadow: 0px min(0.2vw, 2px) min(0.78vw, 8px) 0px rgba(0, 0, 0, 0.25);
}

.shadow-4-12 {
  box-shadow: 0px min(0.39vw, 4px) min(1.17vw, 12px) 0px
    rgba(208, 208, 208, 0.25);
}

.shadow-4-4 {
  filter: drop-shadow(
    0px min(0.39vw, 4px) min(0.39vw, 4px) rgba(0, 0, 0, 0.15)
  );
}

.shadow-table-btn-2 {
  box-shadow: 0px min(0.39vw, 4px) min(0.78vw, 8px) min(-0.39vw, -4px)
    rgba(76, 78, 100, 0.42);
}

.shadow-table-btn {
  box-shadow: 0px min(0.39vw, 4px) min(1.17vw, 12px) 0px
    rgba(208, 208, 208, 0.25);
}

.shadow-menu-btn {
  box-shadow: 0px min(0.2vw, 2px) min(0.78vw, 8px) rgba(0, 0, 0, 0.14);
}

.shadow-pos-setting-card {
  box-shadow: 0px min(0.2vw, 2px) min(0.78vw, 8px) 0px #00000022;
}

.shadow-logout-avartar {
  box-shadow: 0px min(0.39vw, 4px) min(0.39vw, 4px) rgba(0, 0, 0, 0.15);
}

.shadow-table-reserved-panel {
  box-shadow: 0px min(0.39vw, 4px) min(1.17vw, 12px) 0px
    rgba(208, 208, 208, 0.25);
}

.logout-avartar:hover {
  background-color: #efefef !important;
}

.modal-input {
  border-radius: min(0.39vw, 4px);
  border: min(0.1vw, 1px) solid #b6bbc6;
  padding-left: min(0.39vw, 4px);
  padding-top: min(0.46vw, 4.69px);
  padding-bottom: min(0.73vw, 7.44px);
  padding-right: min(0.88vw, 9px);
  text-align: right;
}

.modal-input-2 {
  border: 0;
  padding: 0;
  padding-right: min(1.27vw, 13px);
  text-align: right;
}

.modal-table-input {
  border-radius: min(0.78vw, 8px);
  border: min(0.1vw, 1px) solid #b6bbc6;
  padding-top: min(0.46vw, 4.69px);
  padding-bottom: min(0.73vw, 7.44px);
}

.modal-table-input::placeholder {
  padding-top: min(0.46vw, 4.69px);
  padding-bottom: min(0.73vw, 7.44px);
}

.search-input {
  border-radius: min(0.78vw, 8px);
  border: min(0.1vw, 1px) solid #b6bbc6;
  padding-left: min(0.93vw, 9.5px);
  padding-top: min(0.46vw, 4.69px);
  padding-bottom: min(0.73vw, 7.44px);
  padding-right: min(2.49vw, 25.5px);
}

.input-placeholder-no-padding::placeholder {
  height: 100%;
}

.no-spinner {
  -moz-appearance: textfield;
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btn-main-active {
  background-color: var(--color-green);
}

.btn-main-active:hover {
  background-color: var(--color-dark-green);
}

.btn-second-active {
  background-color: var(--color-grey-2);
}

.btn-second-active:hover {
  background-color: var(--color-dark-grey-2);
}

.btn-error-active {
  background-color: var(--color-orange-red);
}

.btn-error-active:hover {
  background-color: var(--color-dark-orange-red);
}

.btn-second-2-active {
  background-color: var(--color-grey-3);
}

.btn-second-2-active:hover {
  background-color: var(--color-dark-grey-3);
}

.disabled-btn {
  background-color: #b6bbc6 !important;
  pointer-events: none !important;
}

.disabled-btn-grey {
  filter: grayscale(100%);
  pointer-events: none !important;
}

.disabled-input {
  background-color: #ededed !important;
}

.menu-btn-active:hover {
  background-color: #dff7e9;
}

.btn-orange {
  background-color: var(--color-orange);
}

.btn-orange:hover {
  background-color: var(--color-dark-orange);
}

.btn-white {
  background-color: white;
}

.btn-white:hover {
  background-color: white;
}

.btn-blue-2 {
  background-color: var(--color-blue-2);
}

.btn-blue-2:hover {
  background-color: var(--color-dark-blue-2);
}

.btn-yellow {
  background-color: var(--color-yellow);
}

.btn-yellow:hover {
  background-color: var(--color-dark-yellow);
}

.btn-grey-5 {
  background-color: var(--color-grey-5);
}

.btn-grey-5:hover {
  background-color: var(--color-dark-grey-5);
}

.btn-table-zone-active {
  background-color: var(--color-red);
  color: #fff;
}

.btn-table-zone-active:hover {
  background-color: var(--color-dark-red);
}

.btn-table-zone-not-active {
  background-color: #fafafa !important;
  color: var(--color-grey-2);
  border: min(0.1vw, 1px) solid rgba(109, 114, 120, 0.5);
}

.btn-table-zone-not-active:hover {
  background-color: #fff;
}

.btn-table-open-active::before {
  content: "";
  background-image: url(/public/img/icons/icon-table-display.svg);
  background-repeat: no-repeat;
  background-size: contain;
  aspect-ratio: 16.5/13.3;
  width: min(1.61vw, 16.5px);
}

.btn-table-open-active.btn-table-not-active::before {
  background-image: url(/public/img/icons/icon-table-gray.svg);
}

.btn-table-not-active {
  color: var(--color-grey-2) !important;
  background-color: var(--color-grey-3) !important;
  pointer-events: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease;
}

.fade-out {
  opacity: 0;
  transition: all 0.5s ease;
}

@media print {
  @page {
    /* size: 80mm 297mm; */
    margin-top: 0 !important;
  }

  body {
    visibility: hidden;
  }
  #section-to-print {
    visibility: visible;
    position: absolute;
    width: 100vw !important;
    font-size: 3.9vw;
    line-height: 2;
    margin-top: 0;
  }

  #section-to-print .text-large {
    font-size: 4.5vw !important;
  }

  #section-to-print .text-small {
    font-size: 3.5vw !important;
  }

  /* receipt */
  #section-to-print-receipt-page {
    visibility: visible;
    position: absolute;
    width: 100vw !important;
    font-size: 3.31vw;
    line-height: 2;
  }

  #section-to-print-receipt-page table th:nth-child(1) {
    width: 5% !important;
  }
  #section-to-print-receipt-page table th:nth-child(2) {
    width: 55% !important;
  }
  #section-to-print-receipt-page table th:nth-child(3) {
    width: 20% !important;
  }
  #section-to-print-receipt-page table th:nth-child(4) {
    width: 20% !important;
  }

  #section-to-print-receipt-page .logo-receipt {
    width: 26.16vw;
  }

  #section-to-print-receipt-page .text-small {
    font-size: 2.65vw !important;
  }

  #section-to-print-receipt-page .th-col-sm {
    width: 10.61vw;
  }

  #section-to-print-receipt-page .th-col-md {
    width: 18.55vw;
  }

  #section-to-print-receipt-page .th-col-lg {
    width: 24.51vw;
  }

  #section-to-print-receipt-page .line-star {
    height: 1.66vw;
  }

  #section-to-print-receipt-page .my-sp-13 {
    margin: 4.30vw 0 !important;
  }

  #section-to-print-receipt-page .pl-sp-13 {
    padding-left: 4.30vw !important;
  }

  /* section-general-print */
  #section-general-print {
    visibility: visible;
    position: absolute;
    width: 100vw !important;
    font-size: 3.31vw;
    line-height: 2;
  }

  #section-general-print .text-p-12 {
    font-size: 3.97vw !important;
  }
}

/* Width */
::-webkit-scrollbar {
  width: min(0.78vw, 8px);
}

/* Track */
::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 9999px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-green);
  border-radius: 9999px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-dark-green);
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.custom-scroll-prev {
  width: min(2.34vw, 24px);
  aspect-ratio: 1;
  height: fit-content;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="%236D788D"/><path d="M10.7401 16.2802C10.5501 16.2802 10.3601 16.2102 10.2101 16.0602C9.92005 15.7702 9.92005 15.2902 10.2101 15.0002L13.2101 12.0002L10.2101 9.00016C9.92005 8.71016 9.92005 8.23016 10.2101 7.94016C10.5001 7.65016 10.9801 7.65016 11.2701 7.94016L14.8001 11.4702C15.0901 11.7602 15.0901 12.2402 14.8001 12.5302L11.2701 16.0602C11.1201 16.2102 10.9301 16.2802 10.7401 16.2802Z" fill="%236D788D"/></svg>');
  background-repeat: no-repeat;
  background-position: center center;
  transform: rotate(-180deg);
  background-color: transparent;
  margin-top: min(0.2vw, 2px);
  margin-right: min(0.98vw, 10px);
}

.custom-scroll-next {
  width: min(2.34vw, 24px);
  aspect-ratio: 1;
  height: fit-content;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="%236D788D"/><path d="M10.7401 16.2802C10.5501 16.2802 10.3601 16.2102 10.2101 16.0602C9.92005 15.7702 9.92005 15.2902 10.2101 15.0002L13.2101 12.0002L10.2101 9.00016C9.92005 8.71016 9.92005 8.23016 10.2101 7.94016C10.5001 7.65016 10.9801 7.65016 11.2701 7.94016L14.8001 11.4702C15.0901 11.7602 15.0901 12.2402 14.8001 12.5302L11.2701 16.0602C11.1201 16.2102 10.9301 16.2802 10.7401 16.2802Z" fill="%236D788D"/></svg>');
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  margin-top: min(0.2vw, 2px);
  margin-left: min(0.98vw, 10px);
}

.table-detail {
  opacity: 0;
  transform: translateX(100%);
  transition: all 300ms ease-in-out;
}

.table-detail.active {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.btn-tab-green-active {
  background-color: var(--color-green);
  font-weight: 700;
}

.btn-tab-green-active:hover {
  background-color: var(--color-dark-green);
}

.btn-transparent-active:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.btn-tab-green-none-active {
  font-weight: 300;
  color: var(--color-grey);
  border: min(0.1vw, 1px) solid rgba(109, 120, 141, 0.5);
}

.btn-tab-green-none-active:hover {
  background-color: var(--color-light-grey-4);
}

.btn-grey-3-active {
  color: var(--color-grey-2);
  background-color: var(--color-grey-3);
}

.btn-grey-3-active:hover {
  background-color: var(--color-dark-grey-3);
}

.filter-white {
  filter: brightness(0) invert(1);
}

table.kitchen-table {
  width: 100%;
  display: table;
}

table.kitchen-table thead {
  background-color: var(--color-blue-2);
  color: #fff;
}

table.kitchen-table th {
  padding-top: min(1.56vw, 16px);
  padding-bottom: min(1.56vw, 16px);
  font-weight: 500;
}

.rotate-180deg {
  transform: rotate(180deg);
}

.custom-datepicker::-webkit-calendar-picker-indicator {
  /* background: url(/public/img/icons/icon-date-green.svg) no-repeat right; */
  background-size: min(2.34vw, 24px);
  background-color: transparent;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.custom-input-datepicker {
  background: url(/public/img/icons/icon-date-green.svg) no-repeat;
  background-position: calc(100% - min(1.46vw, 15px));
  background-size: min(2.34vw, 24px);
  background-color: transparent;
}

.custom-input-datepicker-container:hover input[type="text"] {
  border-color: var(--color-green);
}

.custom-input-date-range {
  background: url(/public/img/icons/icon-date-green.svg) no-repeat;
  background-position: calc(100% - min(1.46vw, 15px));
  background-size: min(1.79vw, 18.37px);
  background-color: transparent;
}

.line-star {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z'/%3E%3C/svg%3E");
  background-size: contain;
  height: 0.49vw;
}
